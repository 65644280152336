@import url(https://static.mux.com/fonts/fonts.css);
html,
body,
#root {
  height: 100%;
}

body {
  font-family: Akkurat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
  margin: 0;
  color: #383838;
}

#root {
  display: flex;
  flex-direction: column;
}

